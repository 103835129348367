import { Button, InfoLabel, Input, makeStyles, shorthands, Switch } from '@fluentui/react-components';
import { Save16Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useAgent } from '../../../libs/hooks';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

import { ParameterField } from './ParameterField';

const useStyles = makeStyles({
    base: {
        display: 'flex',
        flexDirection: 'column',
    },
    Input: {
        width: '100%',
        minHeight: '25px',
        marginBottom: '10px',
    },
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
    },
});

const PortalDialog: React.FC = () => {
    const styles = useStyles();
    const agent = useAgent();
    //const promptSettings = useAppSelector((state: RootState) => state.promptSettings);
    const customSettings = useAppSelector((state: RootState) => state.customSettings);
    const [allowPortal, setAllowPortal] = useState<boolean>(false);
    const [portalHostName, setPortalHostname] = useState<string>(window.location.hostname.split('.')[0]);
    const [portalName, setPortalName] = useState<string>(customSettings.customSettings.portalName);
    const [portalBannerColor, setPortalBannerColor] = useState<string>(customSettings.customSettings.portalBannerColor);
    const [portalBannerLink, setPortalBannerLink] = useState<string>('');
    useEffect(() => {
        setAllowPortal(customSettings.customSettings.allowPortal);
        customSettings.customSettings.portalHostName === ''
            ? setPortalHostname(window.location.hostname.split('.')[0])
            : setPortalHostname(customSettings.customSettings.portalHostName);
        customSettings.customSettings.portalName === ''
            ? setPortalName('Portal')
            : setPortalName(customSettings.customSettings.portalName);
        customSettings.customSettings.portalBannerColor === ''
            ? setPortalBannerColor('#a500ff')
            : setPortalBannerColor(customSettings.customSettings.portalBannerColor);
        customSettings.customSettings.portalBannerLink !== undefined &&
            setPortalBannerLink(customSettings.customSettings.portalBannerLink);
    }, [customSettings.customSettings]);
    const handleSaveClick = () => {
        agent
            .savePortalSetting(allowPortal, portalHostName, portalName, portalBannerColor, portalBannerLink)
            .catch((error: unknown) => {
                console.error('Error in handleSave:', error);
            });
    };

    return (
        <div className={styles.base}>
            <div className={styles.button}>
                <h4>
                    <u>Configuration</u>
                    <InfoLabel size="small" info="Define settings for portal integration"></InfoLabel>
                </h4>
                <Button onClick={handleSaveClick} icon={<Save16Regular />} />
            </div>
            <ParameterField
                required={false}
                info="Enable Portal Link to enable users to go to the portal by clicking the Banner Title"
                bannerTitle="Enable Portal Link"
            >
                <Switch
                    checked={allowPortal}
                    onChange={(e) => {
                        const value = e.target.checked;
                        setAllowPortal(value);
                    }}
                />
            </ParameterField>
            {allowPortal && (
                <>
                    <ParameterField
                        info="Hostname of the Agent managing the portal"
                        bannerTitle="Portal Master Address"
                    >
                        <Input
                            className={styles.Input}
                            value={portalHostName}
                            onChange={(e) => {
                                setPortalHostname(e.target.value);
                            }}
                        />
                    </ParameterField>
                </>
            )}
            {allowPortal && portalHostName === window.location.hostname && (
                <>
                    <ParameterField info="Name of the Portal" bannerTitle="Portal Banner Title">
                        <Input
                            className={styles.Input}
                            value={portalName}
                            onChange={(e) => {
                                setPortalName(e.target.value);
                            }}
                        />
                    </ParameterField>
                    <ParameterField info="Color of the Portal Banner" bannerTitle="Portal Banner Color">
                        <Input
                            className={styles.Input}
                            value={portalBannerColor}
                            onChange={(e) => {
                                setPortalBannerColor(e.target.value);
                            }}
                        />
                    </ParameterField>
                    <ParameterField
                        info="Link for the Home icon on the Portal page"
                        bannerTitle="Portal Banner Home Link"
                    >
                        <Input
                            className={styles.Input}
                            value={portalBannerLink}
                            onChange={(e) => {
                                setPortalBannerLink(e.target.value);
                            }}
                        />
                    </ParameterField>
                </>
            )}
        </div>
    );
};

export default PortalDialog;

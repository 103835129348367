import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useAccount, useMsal } from '@azure/msal-react';
import { ComponentType } from 'react';

enum Role {
    'AteaChatCopilot.None' = 0,
    'AteaChatCopilot.ChatUser',
    'AteaChatCopilot.User',
    'AteaChatCopilot.Contributor',
    'AteaChatCopilot.Administrator',
    'AteaChatCopilot.Developer',
}

const GetAccount = () => {
    const { accounts } = useMsal();
    const account: AccountInfo | null = useAccount(accounts[0] || {});
    return account;
};

// Helper function to check if the user has any of the specific role
const isAuthorized = (account: AccountInfo | null, allowedRoles: Role[]): boolean => {
    if (!account) {
        account = GetAccount();
    }
    const claims = account?.idTokenClaims?.roles;
    const claimsAsRoles = claims?.map((claim) => Role[claim as keyof typeof Role]);
    //console.log('Claims as roles', claimsAsRoles);
    const allowed = allowedRoles.some((r) => claimsAsRoles?.includes(r));
    //console.log(account?.username + ' is authorized: ' + allowed);
    return allowed;
};

// Higher-Order Component for role checking
export const AuthorizedComponent = <P extends object>(
    WrappedComponent: ComponentType<P>,
    roles: Role[],
    AlternateComponent?: ComponentType<P>,
) => {
    const RoleCheckingComponent = (props: P) => {
        const account = GetAccount();
        //console.log('Defined roles for ' + WrappedComponent.name, roles);

        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Popup}
                loadingComponent={() => <div>Loading...</div>}
            >
                {isAuthorized(account, roles) ? (
                    <WrappedComponent {...props} />
                ) : AlternateComponent ? (
                    <AlternateComponent {...props} />
                ) : (
                    <div className="UnauthorizedUser"></div>
                )}
            </MsalAuthenticationTemplate>
        );
    };

    RoleCheckingComponent.displayName = `AuthorizedComponent(${
        (WrappedComponent.displayName ?? WrappedComponent.name) || 'Component'
    })`;

    return RoleCheckingComponent;
};

export { Role, isAuthorized };

import { Button, Text, Tooltip, makeStyles } from '@fluentui/react-components';
import debug from 'debug';
import { useCallback } from 'react';
import { Constants } from '../../../Constants';
import { GetResponseOptions } from '../../../libs/hooks/useChat';
import { AlertType } from '../../../libs/models/AlertType';
import { ChatMessageType, UserFeedback } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import {
    editConversationInput,
    updateBotResponseStatus,
    updateMessageProperty,
} from '../../../redux/features/conversations/conversationsSlice';
import { ThumbDislike16, ThumbLike16 } from '../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        'place-content': 'flex-end',
        alignItems: 'center',
    },
});

interface IUserFeedbackProps {
    messageIndex: number;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}
const log = debug(Constants.debug.root).extend('chat-input');

export const UserFeedbackActions: React.FC<IUserFeedbackProps> = ({ messageIndex, onSubmit }) => {
    const classes = useClasses();

    const dispatch = useAppDispatch();

    //const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        onSubmit({ value, messageType, chatId: selectedId }).catch((error: unknown) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };
    const onUserFeedbackProvided = useCallback(
        (positive: boolean) => {
            dispatch(
                updateMessageProperty({
                    chatId: selectedId,
                    messageIdOrIndex: messageIndex,
                    property: 'userFeedback',
                    value: positive ? UserFeedback.Positive : UserFeedback.Negative,
                    frontLoad: true,
                }),
            );
        },
        [dispatch, messageIndex, selectedId],
    );

    return (
        <div className={classes.root}>
            <Text color="gray" size={200}>
                {/* AI-generated content may be incorrect */}
            </Text>
            <Tooltip content={'Like bot message'} relationship="label">
                <Button
                    icon={<ThumbLike16 />}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={() => {
                        onUserFeedbackProvided(true);
                        handleSubmit('👍');
                    }}
                />
            </Tooltip>
            <Tooltip content={'Dislike bot message'} relationship="label">
                <Button
                    icon={<ThumbDislike16 />}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={() => {
                        onUserFeedbackProvided(false);
                        handleSubmit('👎');
                    }}
                />
            </Tooltip>
        </div>
    );
};

import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';
import { CustomSettings } from './libs/models/CustomSettings';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#005300',
    20: '#006100',
    30: '#006E00',
    40: '#007c00',
    50: '#008A00',
    60: '#008A00',
    70: '#008A00',
    80: '#007c00',
    90: '#006E00',
    100: '#1A881A',
    110: '#33A133',
    120: '#33A133',
    130: '#33A133',
    140: '#66B966',
    150: '#99D099',
    160: '#66B966',
};
export const getBrandVariants = (customSettings: CustomSettings) => {
    const brandVariants: BrandVariants = {
        10: customSettings.brandVariants.v10,
        20: customSettings.brandVariants.v20,
        30: customSettings.brandVariants.v30,
        40: customSettings.brandVariants.v40,
        50: customSettings.brandVariants.v50,
        60: customSettings.brandVariants.v60,
        70: customSettings.brandVariants.v70,
        80: customSettings.brandVariants.v80,
        90: customSettings.brandVariants.v90,
        100: customSettings.brandVariants.v100,
        110: customSettings.brandVariants.v110,
        120: customSettings.brandVariants.v120,
        130: customSettings.brandVariants.v130,
        140: customSettings.brandVariants.v140,
        150: customSettings.brandVariants.v150,
        160: customSettings.brandVariants.v160,
    };
    return brandVariants;
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#e8ebf9',
};
export const createSemanticKernelLightTheme = (
    semanticKernelBrandRamp: BrandVariants,
): Theme & { colorMeBackground: string } => {
    return {
        ...createLightTheme(semanticKernelBrandRamp),
        colorMeBackground: '#e8ebf9',
    };
};
export const createSemanticKernelDarkTheme = (
    semanticKernelBrandRamp: BrandVariants,
): Theme & { colorMeBackground: string } => {
    return {
        ...createDarkTheme(semanticKernelBrandRamp),
        colorMeBackground: '#2b2b3e',
    };
};
export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});

import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableColumnDefinition,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellProps,
    TableRow,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import { PersonAdd20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { useState } from 'react';
import { AuthorizedComponent, Role } from '../../../libs/auth/AuthorizationHelper';
import { useRole } from '../../../libs/hooks/useRole';
import { EntraIDRole, IRoleUser } from '../../../libs/models/ChatUser';
import { ChangeUserRoleDropdown } from '../../users/ChangeUserRoleDropdown';
import { DeleteUserDialog } from '../../users/DeleteUserDialog';
import { AddUserDialog } from '../AddUserDialog';
import { TabView } from './TabView';

const useClasses = makeStyles({
    functional: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin('0', '0', tokens.spacingVerticalS, '0'),
    },
    addUserButton: {
        width: '128px',
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    tableHeader: {
        fontWeight: tokens.fontSizeBase600,
    },
    emailColumn: {
        '@media (max-width: 744px)': {
            display: 'none',
        },
    },
});

interface TableItem {
    id: string;
    name: string;
    photo?: object;
    email: string;
    role: number;
    roles: EntraIDRole[];
}

const UsersTab: React.FC = () => {
    const classes = useClasses();
    const role = useRole();
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [resources, setResources] = useState<IRoleUser[]>([]);
    const [entraIdRoles, setEntraIdRoles] = useState<EntraIDRole[]>([]);
    React.useEffect(() => {
        if (resources.length === 0) {
            void role.getUsersWithRole().then((source) => {
                setResources([...source]);
            });
        }
        if (entraIdRoles.length === 0) {
            void role.getEntraIdRoles().then((source) => {
                setEntraIdRoles([...source]);
            });
        }
    });

    const addUser = (user: IRoleUser) => {
        setResources([...resources, user]);
    };

    const { columns, rows } = useTable(resources, setResources, entraIdRoles);
    return (
        <TabView title="Users">
            {/* TODO: Update with a proper button that calls a new component for user management  */}
            <div className={classes.functional}>
                <AddUserDialog
                    open={openAddUserDialog}
                    closeDialog={() => {
                        setOpenAddUserDialog(false);
                    }}
                    entraIdRoles={entraIdRoles}
                    addUser={addUser}
                    resources={resources}
                />
                <Button
                    className={classes.addUserButton}
                    icon={<PersonAdd20Regular />}
                    data-testid="settingsMenuItem"
                    onClick={() => {
                        setOpenAddUserDialog(true);
                    }}
                >
                    Add User
                </Button>
            </div>
            <Table aria-label="External resource table" className={classes.table}>
                <TableHeader>
                    <TableRow>{columns.map((column) => column.renderHeaderCell())}</TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map((item) => (
                        <TableRow key={item.email}>{columns.map((column) => column.renderCell(item))}</TableRow>
                    ))}
                </TableBody>
            </Table>
        </TabView>
    );
};

function useTable(
    resources: IRoleUser[],
    setResources: React.Dispatch<React.SetStateAction<IRoleUser[]>>,
    entraIdRoles: EntraIDRole[] = [],
) {
    const classes = useClasses();
    const deleteUser = (userId: string) => {
        setResources(resources.filter((user) => user.id !== userId));
    };
    const headerSortProps = (columnId: TableColumnId): TableHeaderCellProps => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const columns: Array<TableColumnDefinition<TableItem>> = [
        createTableColumn<TableItem>({
            columnId: 'name',
            renderHeaderCell: () => (
                <TableHeaderCell key="´name" {...headerSortProps('name')}>
                    Name
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCell key={item.name}>{item.name}</TableCell>,
            compare: (a, b) => {
                const comparison = a.name.localeCompare(b.name);
                return getSortDirection('name') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'email',
            renderHeaderCell: () => (
                <TableHeaderCell className={classes.emailColumn} key="email" {...headerSortProps('email')}>
                    Email
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell className={classes.emailColumn} key={item.email}>
                    {item.email}
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.email.localeCompare(b.email);
                return getSortDirection('email') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'role',
            renderHeaderCell: () => <TableHeaderCell key="role">Role</TableHeaderCell>,
            renderCell: (item) => (
                <TableCell key={item.role}>
                    <ChangeUserRoleDropdown
                        dropdownProps={{
                            id: `${item.id}-controlled`,
                        }}
                        user={{
                            id: item.id,
                            displayName: item.name,
                            userPrincipalName: item.email,
                            mail: item.email,
                            highestRole: item.role,
                            roles: item.roles,
                        }}
                        resources={resources}
                        setResources={setResources}
                        entraIdRoles={entraIdRoles}
                    ></ChangeUserRoleDropdown>
                </TableCell>
            ),
        }),
        createTableColumn<TableItem>({
            columnId: 'delete',
            renderHeaderCell: () => <TableHeaderCell key="delete" style={{ width: '30px' }} />,
            renderCell: (item) => (
                <TableCell key={item.id}>
                    <DeleteUserDialog
                        user={{
                            id: item.id,
                            displayName: item.name,
                            userPrincipalName: item.email,
                            mail: item.email,
                            highestRole: item.role,
                            roles: item.roles,
                        }}
                        deleteUser={deleteUser}
                    />
                </TableCell>
            ),
        }),
    ];

    const items = resources.map((item) => ({
        id: item.id,
        name: item.displayName,
        email: item.mail,
        role: item.highestRole,
        roles: item.roles,
    }));

    const {
        sort: { getSortDirection, toggleColumnSort, sortColumn },
    } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );

    if (sortColumn) {
        items.sort((a, b) => {
            const compare = columns.find((column) => column.columnId === sortColumn)?.compare;
            return compare?.(a, b) ?? 0;
        });
    }

    return { columns, rows: items };
}

const RoleAwareUsersTab = AuthorizedComponent(UsersTab, [
    Role['AteaChatCopilot.Administrator'],
    Role['AteaChatCopilot.Developer'],
]);

export { RoleAwareUsersTab as UsersTab };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelDeployment } from '../../../libs/models/ModelDeployments';
import { initialState, ModelDeploymentsState } from './modelDeploymentsState';

const modelSlice = createSlice({
    name: 'modelDeployments',
    initialState,
    reducers: {
        setModelDeployments: (state: ModelDeploymentsState, action: PayloadAction<ModelDeployment[]>) => {
            state.modelSettings = action.payload;
        },
    },
});

export default modelSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromptSettings } from '../../../libs/models/PromptSettings';
import { initialState, PromptSettingsState } from './PromptSettingsState';

const promptsSlice = createSlice({
    name: 'promptSettings',
    initialState,
    reducers: {
        setPromptSettings: (state: PromptSettingsState, action: PayloadAction<PromptSettings>) => {
            state.promptSettings = action.payload;
        },
    },
});

export default promptsSlice.reducer;

import { Subtitle1 } from '@fluentui/react-components';
import React from 'react';
import { AppState, useClasses } from '../../App';
import { ToggleChatListButton } from '../../components/header/ToggleChatListButton';
import { UserSettingsMenu } from '../../components/header/UserSettingsMenu';
import { BackendProbe, ChatView, Error, Loading } from '../../components/views';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { AuthorizedComponent, Role, isAuthorized } from '../../libs/auth/AuthorizationHelper';
import { useAgent } from '../../libs/hooks';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { Portal } from '../Portal';
import { AteaToast } from '../shared/AteaToast';
import { Home28 } from '../shared/BundledIcons';
import { LogoutUnauthorized } from '../views/LogoutUnauthorized';

interface ChatProps {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}

const Chat: React.FC<ChatProps> = ({ classes, appState, setAppState }) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                  AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                  AppState.LoadingChats,
        );
    }, [setAppState]);
    const isAuthorizedChatMenu = isAuthorized(null, [
        Role['AteaChatCopilot.User'],
        Role['AteaChatCopilot.Contributor'],
        Role['AteaChatCopilot.Administrator'],
        Role['AteaChatCopilot.Developer'],
    ]);
    const agent = useAgent();
    const { customSettings } = useAppSelector((state: RootState) => state.customSettings);

    const goToPortal = () => {
        const portalUrl = agent.getUrl(customSettings.portalHostName);
        window.location.href = `${portalUrl}?view=portal`;
    };
    const goToPortalBannerLink = () => {
        if (customSettings.portalBannerLink) {
            window.open(customSettings.portalBannerLink, '_blank');
        }
    };

    return (
        <div className={classes.container}>
            <div
                className={classes.header}
                style={{
                    backgroundColor:
                        appState !== AppState.ChatPortal
                            ? customSettings.brandVariants.v70
                            : customSettings.portalBannerColor,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '32px', marginLeft: '5px' }}>
                        {isAuthorizedChatMenu && appState !== AppState.ChatPortal && <ToggleChatListButton />}
                        {isAuthorizedChatMenu && appState === AppState.ChatPortal && (
                            <Home28 onClick={goToPortalBannerLink} style={{ cursor: 'pointer' }} />
                        )}
                    </div>
                    <div>
                        {customSettings.allowPortal && (
                            <Subtitle1
                                onClick={goToPortal}
                                as="h1"
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginLeft: '0px',
                                    paddingLeft: '5px',
                                    cursor: appState !== AppState.ChatPortal ? 'pointer' : 'default',
                                }}
                            >
                                {appState !== AppState.ChatPortal ? customSettings.appTitle : customSettings.portalName}
                            </Subtitle1>
                        )}
                        {!customSettings.allowPortal && (
                            <Subtitle1
                                as="h1"
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginLeft: '0px',
                                    paddingLeft: '5px',
                                }}
                            >
                                {customSettings.appTitle}
                            </Subtitle1>
                        )}
                    </div>
                </div>
                {appState > AppState.SettingUserInfo && appState !== AppState.ChatPortal && (
                    <div className={classes.cornerItems}>
                        <div className={classes.cornerItems}>
                            {/* <PluginGallery /> */}
                            <UserSettingsMenu
                                setLoadingState={() => {
                                    setAppState(AppState.SigningOut);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
            {appState === AppState.Chat && <ChatView />}
            {appState === AppState.ChatPortal && <Portal />}
            <AteaToast />
        </div>
    );
};

const UnauthorizedChat: React.FC<ChatProps> = ({ classes, appState, setAppState }) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                  AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                  AppState.LoadingChats,
        );
    }, [setAppState]);
    const { customSettings } = useAppSelector((state: RootState) => state.customSettings);
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '32px', marginLeft: '0px', paddingLeft: '5px' }}>
                        <Home28 />
                    </div>
                    <div>
                        <Subtitle1
                            onClick={() => {
                                if (appState === AppState.Chat) {
                                    setAppState(AppState.ChatPortal);
                                }
                            }}
                            as="h1"
                            style={{
                                whiteSpace: 'nowrap',
                                marginLeft: '0px',
                                paddingLeft: '5px',
                                cursor: appState !== AppState.ChatPortal ? 'pointer' : 'default',
                            }}
                        >
                            {appState !== AppState.ChatPortal ? customSettings.appTitle : 'Atea Chat Copilot Portal'}
                        </Subtitle1>
                    </div>
                </div>
            </div>
            {appState === AppState.ChatPortal ? (
                <div className="UnauthorizedUser" style={{ height: '100%', backgroundColor: '#F5F5F5' }}>
                    <Portal />
                </div>
            ) : (
                <>
                    {appState > AppState.SettingUserInfo && (
                        <div className="UnauthorizedUser">
                            <LogoutUnauthorized />
                        </div>
                    )}
                    {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
                    {appState === AppState.ErrorLoadingUserInfo && (
                        <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
                    )}
                </>
            )}
        </div>
    );
};

const RoleAwareChat = AuthorizedComponent(
    Chat,
    [
        Role['AteaChatCopilot.ChatUser'],
        Role['AteaChatCopilot.User'],
        Role['AteaChatCopilot.Contributor'],
        Role['AteaChatCopilot.Administrator'],
        Role['AteaChatCopilot.Developer'],
    ],
    UnauthorizedChat,
);

export { RoleAwareChat as Chat };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCustomSettings, CustomSettings } from '../../../libs/models/CustomSettings';
import { fetchCustomSettings } from '../../app/thunks';
import { CustomSettingsState } from './CustomSettingsState';

export const initialState: CustomSettingsState = {
    customSettings: createCustomSettings(),
    status: 'idle',
    error: null,
};

const settingsSlice = createSlice({
    name: 'customSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomSettings.fulfilled, (state, action: PayloadAction<CustomSettings>) => {
                state.status = 'succeeded';
                state.customSettings = action.payload;
            })
            .addCase(fetchCustomSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? '';
            });
    },
});

export default settingsSlice.reducer;

import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { IAgentOptions } from '../libs/models/Agent';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 744px)': {
            justifyContent: 'center',
        },
        '@media (min-width: 744px)': {
            justifyContent: 'space-between',
        },
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    image: {
        objectFit: 'cover',
        width: '120px',
        height: '120px',
        borderRadius: '50%',
    },
    card: {
        margin: '0px',
        padding: '0px',
        width: '280px',
        overflow: 'hidden',
        wordWrap: 'break-word',
        borderRadius: '10px',
        height: '280px',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
    },
    centerFlex: {
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
    },
    hide: {
        '@media (max-width: 744px)': {
            display: 'none',
        },
    },
});

export interface PortalCardProps {
    agentSettings: IAgentOptions;
}

export const PortalCard: React.FC<PortalCardProps> = ({ agentSettings }) => {
    const classes = useStyles();
    const handleRedirect = (url: string) => {
        window.location.href = url;
    };
    return (
        <div
            style={{ backgroundColor: '#FEFEFE', cursor: 'pointer', border: '1px solid #D1D1D1' }}
            className={classes.card}
            onClick={() => {
                handleRedirect(agentSettings.hostUrl);
            }}
        >
            <div
                className={(classes.card, classes.centerFlex)}
                style={{ backgroundColor: agentSettings.color, height: '40px' }}
            >
                <Subtitle1
                    as="h1"
                    style={{
                        whiteSpace: 'nowrap',
                        color: '#ffffff',
                    }}
                >
                    {agentSettings.name}
                </Subtitle1>
            </div>
            <div className={(classes.card, classes.centerFlex)} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <img className={classes.image} src={agentSettings.welcomeImageUrl} />
            </div>
            <div
                className={(classes.card, classes.centerFlex)}
                style={{
                    paddingTop: '0px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingBottom: '10px',
                }}
            >
                {agentSettings.welcomeSubtitle}
            </div>
        </div>
    );
};

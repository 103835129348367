import type { DropdownProps, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { Dropdown, Option, makeStyles, useId } from '@fluentui/react-components';
import * as React from 'react';
import { ModelDeployment } from '../../libs/models/ModelDeployments';
import { useAppSelector } from '../../redux/app/hooks';

const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
    },
    label: {
        '@media (max-width: 744px)': {
            display: 'none',
        },
    },
    dropdown: {
        minWidth: 'unset',
        width: '150px',
    },
});

const OpenAIModelSelector: React.FC = (props: Partial<DropdownProps>) => {
    const comboId = useId('combo-openai-model-selector');
    const styles = useStyles();
    const modelDeployments = useAppSelector((state) => state.modelDeployments.modelSettings);
    const [model, setModel] = React.useState(localStorage.getItem('modelDeploymentName') ?? '');
    const [options] = React.useState<ModelDeployment[]>(modelDeployments);

    React.useEffect(() => {
        if (model === '' && options.length > 0) {
            setModel(options[0].deployment);
            localStorage.setItem('modelDeploymentName', options[0].deployment);
        }
    }, [options, model]);

    return (
        <div className={styles.root}>
            <label className={styles.label} id={comboId}>
                Model
            </label>
            <Dropdown
                aria-labelledby={comboId}
                placeholder={options[0].deployment}
                {...props}
                appearance="underline"
                inlinePopup={true}
                defaultValue={model}
                className={styles.dropdown}
                onOptionSelect={(_: SelectionEvents, data: OptionOnSelectData) => {
                    const value = data.optionValue ?? '';
                    setModel(value);
                    localStorage.setItem('modelDeploymentName', value);
                }}
            >
                {options.map((option) => (
                    <Option key={option.deployment}>{option.deployment}</Option>
                ))}
            </Dropdown>
        </div>
    );
};
export default OpenAIModelSelector;

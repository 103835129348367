import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { ModelDeployments } from '../models/ModelDeployments';
import { PromptSettings } from '../models/PromptSettings';
import { PromptService } from '../services/PromptService';

export const usePrompt = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const promptService = new PromptService();
    const getSettings = async (): Promise<PromptSettings> => {
        const settings = await promptService.getPromptsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
        return settings;
    };
    const setSettings = async (promptSettings: PromptSettings) => {
        let errorMessage = 'Prompt saved successfully';
        let alert = AlertType.Success;
        const response = await promptService.setPromptsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            promptSettings,
        );
        if (!response) {
            errorMessage = 'Failed to save prompt';
            alert = AlertType.Error;
        }
        dispatch(addAlert({ message: errorMessage, type: alert }));
    };
    const getModelDeployments = async (): Promise<ModelDeployments> => {
        const modelDeployments = await promptService.getModelDeployments(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
        return modelDeployments;
    };

    return {
        getSettings,
        setSettings,
        getModelDeployments,
    };
};

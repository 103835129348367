import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomSettings } from '../../libs/models/CustomSettings';
import { FeaturesSetting } from '../../libs/models/FeaturesSetting';
import { PromptSettings } from '../../libs/models/PromptSettings';
import { BackendServiceUrl } from '../../libs/services/BaseService';

export const fetchCustomSettings = createAsyncThunk('settings/fetchCustomSettings', async () => {
    const base = BackendServiceUrl.replace(/\/$/, '');
    const response = await fetch(`${base}/settings`);
    const settings: CustomSettings = (await response.json()) as CustomSettings;
    return settings;
});

export const fetchPromptSettings = createAsyncThunk('settings/fetchPromptSettings', async () => {
    const base = BackendServiceUrl.replace(/\/$/, '');
    const response = await fetch(`${base}/prompts`);
    const settings: PromptSettings = (await response.json()) as PromptSettings;
    return settings;
});

export const fetchFeaturesSetting = createAsyncThunk('settings/fetchFeaturesSetting', async () => {
    const base = BackendServiceUrl.replace(/\/$/, '');
    const response = await fetch(`${base}/features`);
    const settings: FeaturesSetting = (await response.json()) as FeaturesSetting;
    return settings;
});

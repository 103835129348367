import { ModelDeployments } from '../models/ModelDeployments';
import { PromptSettings } from '../models/PromptSettings';
import { BaseService } from './BaseService';

export class PromptService extends BaseService {
    public getPromptsAsync = async (accessToken: string) => {
        const promptsResponse = await this.getResponseAsync<PromptSettings>(
            {
                commandPath: 'prompts',
                method: 'GET',
            },
            accessToken,
        );
        return promptsResponse;
    };

    public setPromptsAsync = async (accessToken: string, prompt: PromptSettings) => {
        const promptsResponse = await this.getResponseAsync<boolean>(
            {
                commandPath: 'prompts',
                method: 'POST',
                body: prompt,
            },
            accessToken,
        );
        return promptsResponse;
    };

    public getModelDeployments = async (accessToken: string) => {
        const modelDeploymentsResponse = await this.getResponseAsync<ModelDeployments>(
            {
                commandPath: 'prompts/modeldeployments',
                method: 'GET',
            },
            accessToken,
        );
        return modelDeploymentsResponse;
    };
}

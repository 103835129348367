import type { DropdownProps } from '@fluentui/react-components';
import { Dropdown, Option, useId } from '@fluentui/react-components';
import * as React from 'react';
import { Role } from '../../libs/auth/AuthorizationHelper';
import { useRole } from '../../libs/hooks/useRole';
import { EntraIDRole, IRoleUser } from '../../libs/models/ChatUser';

interface ChangeUserRoleDropdownProps {
    dropdownProps: Partial<DropdownProps>;
    resources: IRoleUser[];
    setResources: React.Dispatch<React.SetStateAction<IRoleUser[]>>;
    user: IRoleUser;
    entraIdRoles: EntraIDRole[];
}

const ChangeUserRoleDropdown: React.FC<ChangeUserRoleDropdownProps> = (props) => {
    const { dropdownProps, resources, setResources, user, entraIdRoles } = props;
    const comboId = useId('combo-controlled');
    const role = useRole();
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([Role[user.highestRole].split('.')[1]]);
    const [value, setValue] = React.useState(Role[user.highestRole].split('.')[1]);

    const onOptionSelect: (typeof dropdownProps)['onOptionSelect'] = (ev, data) => {
        console.log(ev);
        const newRole = entraIdRoles.filter((role) => role.roleName === data.optionText)[0];
        // Remove user from old role(s)
        user.roles.forEach((r) => {
            void role.deleteUser(user.id, r.id);
        });
        // Add user to new role
        void role.addUser(user.id, newRole.id);
        // Update list with new user settings
        const newUser = { ...user, highestRole: newRole.name, roles: [newRole] };
        const newResources = resources.filter((u) => u.id !== user.id);
        newResources.push(newUser);
        setResources(newResources);
        setSelectedOptions(data.selectedOptions);
        setValue(data.optionText ?? '');
    };
    return (
        <div>
            <Dropdown
                id={`${comboId}-controlled`}
                value={value}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
                appearance="filled-lighter"
            >
                {entraIdRoles.map((role) => (
                    <Option
                        key={role.id}
                        text={role.roleName}
                        disabled={role.roleName === Role[user.highestRole].split('.')[1]}
                    >
                        {role.roleName}
                    </Option>
                ))}
            </Dropdown>
        </div>
    );
};

export { ChangeUserRoleDropdown };

import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { FeaturesSetting } from '../models/FeaturesSetting';
import { FeaturesService } from '../services/FeaturesService';

export const useFeaturesSetting = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const featuresService = new FeaturesService();
    const getFeaturesSetting = async (): Promise<FeaturesSetting> => {
        const featuresSetting = await featuresService.getFeaturesSettingAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
        return featuresSetting;
    };
    const setFeaturesSetting = async (featuresSetting: FeaturesSetting) => {
        let errorMessage = 'Features setting saved successfully';
        let alert = AlertType.Success;
        const response = await featuresService.setFeaturesSettingAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            featuresSetting,
        );
        if (!response) {
            errorMessage = 'Failed to save features setting';
            alert = AlertType.Error;
        }
        dispatch(addAlert({ message: errorMessage, type: alert }));
    };

    return {
        getFeaturesSetting,
        setFeaturesSetting,
    };
};

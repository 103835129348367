import { Divider, InfoLabel, makeStyles, shorthands, Switch, tokens } from '@fluentui/react-components';
import { useCallback } from 'react';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { isAuthorized } from '../../../libs/auth/AuthorizationHelper';
import { useFeaturesSetting } from '../../../libs/hooks/useFeaturesSetting';
import { FeaturesSetting } from '../../../libs/models/FeaturesSetting';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys, Setting } from '../../../redux/features/app/AppState';
import { toggleFeatureFlag } from '../../../redux/features/app/appSlice';
import { toggleMultiUserConversations } from '../../../redux/features/conversations/conversationsSlice';

const useClasses = makeStyles({
    feature: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalNone),
    },
    featureDescription: {
        paddingLeft: '5%',
        paddingBottom: tokens.spacingVerticalS,
    },
});

interface ISettingsSectionProps {
    setting: Setting;
    contentOnly?: boolean;
}

export const SettingSection: React.FC<ISettingsSectionProps> = ({ setting, contentOnly }) => {
    const classes = useClasses();
    const { features } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const featuresSetting = useFeaturesSetting();

    const onFeatureChange = useCallback(
        (featureKey: FeatureKeys) => {
            dispatch(toggleFeatureFlag(featureKey));
            if (featureKey === FeatureKeys.MultiUserChat) {
                dispatch(toggleMultiUserConversations());
            }
            if (featureKey === FeatureKeys.DarkMode) {
                localStorage.setItem('DarkMode', (!features[FeatureKeys.DarkMode].enabled).toString());
            }
            if (featureKey === FeatureKeys.PluginsPlannersAndPersonas) {
                localStorage.setItem(
                    'personasActive',
                    (!features[FeatureKeys.PluginsPlannersAndPersonas].enabled).toString(),
                );
            }

            const updateFeatureSetting = (featureKey: FeatureKeys) => {
                const newFeaturesSetting: FeaturesSetting = {
                    rlhf:
                        featureKey === FeatureKeys.RLHF
                            ? !features[FeatureKeys.RLHF].enabled
                            : features[FeatureKeys.RLHF].enabled,
                    modelSelection:
                        featureKey === FeatureKeys.ModelSelection
                            ? !features[FeatureKeys.ModelSelection].enabled
                            : features[FeatureKeys.ModelSelection].enabled,
                    webSearch:
                        featureKey === FeatureKeys.WebSearch
                            ? !features[FeatureKeys.WebSearch].enabled
                            : features[FeatureKeys.WebSearch].enabled,
                    webScraping:
                        featureKey === FeatureKeys.WebScraping
                            ? !features[FeatureKeys.WebScraping].enabled
                            : features[FeatureKeys.WebScraping].enabled,
                    textToImage:
                        featureKey === FeatureKeys.TextToImage
                            ? !features[FeatureKeys.TextToImage].enabled
                            : features[FeatureKeys.TextToImage].enabled,
                };

                void featuresSetting.setFeaturesSetting(newFeaturesSetting).then(() => {
                    dispatch({
                        type: 'featuresSetting/setFeaturesSetting',
                        payload: newFeaturesSetting,
                    });
                });
            };

            if (
                featureKey === FeatureKeys.RLHF ||
                featureKey === FeatureKeys.ModelSelection ||
                featureKey === FeatureKeys.WebSearch ||
                featureKey === FeatureKeys.WebScraping ||
                featureKey === FeatureKeys.TextToImage
            ) {
                updateFeatureSetting(featureKey);
            }
        },
        [dispatch, features, featuresSetting],
    );

    return (
        <>
            {!contentOnly && <h3>{setting.title}</h3>}
            {setting.description && <p>{setting.description}</p>}
            <div
                style={{
                    display: 'flex',
                    flexDirection: setting.stackVertically ? 'column' : 'row',
                    flexWrap: 'wrap',
                }}
            >
                {setting.features.map((key) => {
                    const feature = features[key];
                    return (
                        <>
                            {key === FeatureKeys.DarkMode && (
                                <h4>
                                    <u>User Settings</u>
                                </h4>
                            )}
                            {key === FeatureKeys.ModelSelection && (
                                <h4>
                                    <u>Application Settings</u>
                                </h4>
                            )}
                            <div
                                key={key}
                                className={classes.feature}
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <Switch
                                    label={feature.label}
                                    checked={feature.enabled}
                                    disabled={
                                        !!feature.inactive ||
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                        (feature.roles ? !isAuthorized(null, feature.roles) : false) ||
                                        (key === FeatureKeys.MultiUserChat && !AuthHelper.isAuthAAD())
                                    }
                                    onChange={() => {
                                        onFeatureChange(key);
                                    }}
                                    data-testid={feature.label}
                                />
                                <InfoLabel size="small" info={feature.description}></InfoLabel>
                            </div>
                        </>
                    );
                })}
            </div>
            {!contentOnly && <Divider />}
        </>
    );
};

import { Toast, ToastTitle, Toaster, useId, useToastController } from '@fluentui/react-components';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';

export const AteaToast: React.FC = () => {
    const toasterId = useId('toaster');
    const { dispatchToast } = useToastController(toasterId);
    const dispatch = useAppDispatch();
    const { alerts } = useAppSelector((state: RootState) => state.app);

    React.useEffect(() => {
        alerts.forEach(({ type, message }, index) => {
            dispatchToast(
                <Toast>
                    <ToastTitle>{message.slice(0, 1000) + (message.length > 1000 ? '...' : '')}</ToastTitle>
                </Toast>,
                { position: 'top-end', intent: type },
            );
            dispatch(removeAlert(index));
        });
    }, [alerts, dispatchToast, dispatch]);

    return (
        <>
            <Toaster toasterId={toasterId} />
        </>
    );
};

import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { Role } from '../auth/AuthorizationHelper';
import { EntraIDRole, IRoleUser } from '../models/ChatUser'; // Import the IRoleUser interface
import { IGetToken, IPath } from '../models/Path';
import { RoleService } from '../services/RoleService';

export const useRole = () => {
    const { instance, inProgress } = useMsal();
    const getUsersWithRole = async (): Promise<IRoleUser[]> => {
        const roleService = new RoleService();
        const usersWithRole = (await roleService.getUserRolesAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        )) as IRoleUser[]; // Cast the response to the IRoleUser[] type
        return usersWithRole;
    };

    const addUser = async (userId: string, groupId: string) => {
        const roleService = new RoleService();
        const usersWithRole = await roleService.addUserRoleAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            userId,
            groupId,
        ); // Cast the response to the IRoleUser[] type
        return usersWithRole;
    };
    const deleteUser = async (userId: string, groupId: string) => {
        const roleService = new RoleService();
        void (await roleService.deleteUserRoleAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            userId,
            groupId,
        ));
    };
    const getEntraIdRoles = async (): Promise<EntraIDRole[]> => {
        const roleService = new RoleService();
        const roles = (await roleService.getEntraIDRolesAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        )) as EntraIDRole[];
        roles.forEach((role) => {
            role.roleName = Role[role.name].split('.')[1];
        });
        return roles;
    };

    const getBlobSASToken = async (blob: IGetToken): Promise<IPath> => {
        const roleService = new RoleService();
        const token = await roleService.getBlobSASTokenAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            blob,
        );
        return token;
    };

    return {
        addUser,
        deleteUser,
        getUsersWithRole,
        getEntraIdRoles,
        getBlobSASToken,
    };
};

import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { CustomSettings } from '../models/CustomSettings';
import { ISettings } from '../models/Settings'; // Import the ISettings interface
import { SettingsService } from '../services/SettingsService';

export const useSettings = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const settingsService = new SettingsService();
    const getSettings = async (): Promise<ISettings> => {
        const settings = (await settingsService.getSettingsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        )) as ISettings; // Cast the response to the Settings type
        return settings;
    };
    const setSettings = async (customSettings: CustomSettings) => {
        let errorMessage = 'Settings saved successfully';
        let alert = AlertType.Success;
        const response = await settingsService.setSettingsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            customSettings,
        );
        if (!response) {
            errorMessage = 'Failed to save settings';
            alert = AlertType.Error;
        }
        dispatch(addAlert({ message: errorMessage, type: alert }));
    };

    return {
        getSettings,
        setSettings,
    };
};

export interface PromptSettings {
    completionTokenLimit: number;
    documentMemoryMinRelevance: number;
    documentMemoryName: string;
    initialBotMessage: string;
    knowledgeCutoffDate: string;
    longTermMemoryExtraction: string;
    longTermMemoryName: string;
    memoryAntiHallucination: string;
    memoryContinuation: string;
    memoryFormat: string;
    memoryIndexName: string;
    planResultsDescription: string;
    proposedPlanBotMessage: string;
    responseFrequencyPenalty: number;
    responsePresencePenalty: number;
    responseTemperature: number;
    responseTokenLimit: number;
    responseTopP: number;
    stepwisePlannerSupplement: string;
    systemAudience: string;
    systemAudienceContinuation: string;
    systemCognitive: string;
    systemDescription: string;
    systemIntent: string;
    systemIntentContinuation: string;
    systemResponse: string;
    workingMemoryExtraction: string;
    workingMemoryName: string;
    defaultModel: string;

    allowPortal: boolean;
    mainAgent: string;
    portalHostName: string;
    portalName: string;
    portalBannerColor: string;
}

export function createPromptSettings(): PromptSettings {
    return {
        completionTokenLimit: 0,
        documentMemoryMinRelevance: 0,
        documentMemoryName: '',
        initialBotMessage: '',
        knowledgeCutoffDate: '',
        longTermMemoryExtraction: '',
        longTermMemoryName: '',
        memoryAntiHallucination: '',
        memoryContinuation: '',
        memoryFormat: '',
        memoryIndexName: '',
        planResultsDescription: '',
        proposedPlanBotMessage: '',
        responseFrequencyPenalty: 0,
        responsePresencePenalty: 0,
        responseTemperature: 0,
        responseTokenLimit: 0,
        responseTopP: 0,
        stepwisePlannerSupplement: '',
        systemAudience: '',
        systemAudienceContinuation: '',
        systemCognitive: '',
        systemDescription: '',
        systemIntent: '',
        systemIntentContinuation: '',
        systemResponse: '',
        workingMemoryExtraction: '',
        workingMemoryName: '',
        defaultModel: '',
        allowPortal: false,
        mainAgent: '',
        portalHostName: '',
        portalName: '',
        portalBannerColor: '',
    };
}

import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useRole } from '../../libs/hooks';
import { IRoleUser } from '../../libs/models/ChatUser';
import { Delete16 } from '../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IDeleteUserProps {
    user: IRoleUser;
    deleteUser: (userId: string) => void;
}

export const DeleteUserDialog: React.FC<IDeleteUserProps> = ({ user, deleteUser }) => {
    const classes = useClasses();
    const role = useRole();

    const onDeleteUser = () => {
        console.log('Deleting user:', user.id, user.displayName);
        user.roles.forEach((r) => {
            console.log(`Removing ${user.displayName} from ${r.displayName}`);
            void role.deleteUser(user.id, r.id);
        });
        deleteUser(user.id);
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete user'} relationship="label">
                    <Button icon={<Delete16 />} appearance="transparent" aria-label="DeleteUser" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Delete user!</DialogTitle>
                    <DialogContent>This action will remove {user.displayName} from your app.</DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onDeleteUser}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

import { FeaturesSetting } from '../models/FeaturesSetting';
import { BaseService } from './BaseService';

export class FeaturesService extends BaseService {
    public getFeaturesSettingAsync = async (accessToken: string) => {
        const featuresResponse = await this.getResponseAsync<FeaturesSetting>(
            {
                commandPath: 'features',
                method: 'GET',
            },
            accessToken,
        );
        return featuresResponse;
    };

    public setFeaturesSettingAsync = async (accessToken: string, featuresSetting: FeaturesSetting) => {
        const featuresResponse = await this.getResponseAsync<boolean>(
            {
                commandPath: 'features',
                method: 'POST',
                body: featuresSetting,
            },
            accessToken,
        );
        return featuresResponse;
    };

    public setFeatureSettingAsync = async (accessToken: string, featureSetting: string, value: boolean) => {
        const featuresResponse = await this.getResponseAsync<boolean>(
            {
                commandPath: `features/${featureSetting}`,
                method: 'POST',
                body: value,
            },
            accessToken,
        );
        return featuresResponse;
    };
}

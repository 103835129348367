import {
    Button,
    Dropdown,
    InfoLabel,
    Input,
    Label,
    Option,
    OptionOnSelectData,
    SelectionEvents,
    Slider,
    Textarea,
    makeStyles,
    shorthands,
    useId,
} from '@fluentui/react-components';
import { Save16Regular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { usePrompt } from '../../../libs/hooks/usePrompt';
import { ModelDeployment } from '../../../libs/models/ModelDeployments';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ParameterField } from './ParameterField';

const useStyles = makeStyles({
    base: {
        display: 'flex',
        flexDirection: 'column',
    },
    Input: {
        width: '100%',
        minHeight: '25px',
        marginBottom: '10px',
    },
    textArea: {
        width: '100%',
        minHeight: '200px',
        marginBottom: '10px',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
    },
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    slider: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sliderComponent: {
        flexBasis: '90%',
        marginRight: '10px',
    },
    sliderLabel: {
        flexBasis: '5%',
        textAlign: 'right',
    },
});

const ConfigurationDialog: React.FC = () => {
    const promptSettings = useAppSelector((state: RootState) => state.promptSettings);
    const dispatch = useAppDispatch();
    const styles = useStyles();
    const prompt = usePrompt();

    const [completionTokenLimit, setCompletionTokenLimit] = useState<number>(
        promptSettings.promptSettings.completionTokenLimit,
    );
    const [documentMemoryMinRelevance, setDocumentMemoryMinRelevance] = useState<number>(
        promptSettings.promptSettings.documentMemoryMinRelevance,
    );
    //const [documentMemoryName, setDocumentMemoryName] = useState<string>(promptSettings.promptSettings.documentMemoryName);
    const [initialBotMessage, setInitialBotMessage] = useState<string>(promptSettings.promptSettings.initialBotMessage);
    //const [knowledgeCutoffDate, setKnowledgeCutoffDate] = useState<Date | null>(promptSettings.promptSettings.knowledgeCutoffDate);
    //const [longTermMemoryExtraction, setLongTermMemoryExtraction] = useState<boolean>(promptSettings.promptSettings.longTermMemoryExtraction);
    //const [longTermMemoryName, setLongTermMemoryName] = useState<string>(promptSettings.promptSettings.longTermMemoryName);
    //const [memoryAntiHallucination, setMemoryAntiHallucination] = useState<boolean>(promptSettings.promptSettings.memoryAntiHallucination);
    //const [memoryContinuation, setMemoryContinuation] = useState<boolean>(promptSettings.promptSettings.memoryContinuation);
    //const [memoryFormat, setMemoryFormat] = useState<string>(promptSettings.promptSettings.memoryFormat);
    //const [memoryIndexName, setMemoryIndexName] = useState<string>(promptSettings.promptSettings.memoryIndexName);
    //const [planResultsDescription, setPlanResultsDescription] = useState<string>(promptSettings.promptSettings.planResultsDescription);
    //const [proposedPlanBotMessage, setProposedPlanBotMessage] = useState<string>(promptSettings.promptSettings.proposedPlanBotMessage);
    //const [responseFrequencyPenalty, setResponseFrequencyPenalty] = useState<number>(promptSettings.promptSettings.responseFrequencyPenalty);
    //const [responsePresencePenalty, setResponsePresencePenalty] = useState<number>(promptSettings.promptSettings.responsePresencePenalty);
    const [responseTemperature, setResponseTemperature] = useState<number>(
        promptSettings.promptSettings.responseTemperature,
    );
    const [responseTokenLimit, setResponseTokenLimit] = useState<number>(
        promptSettings.promptSettings.responseTokenLimit,
    );
    const [responseTopP, setResponseTopP] = useState<number>(promptSettings.promptSettings.responseTopP);
    //const [stepwisePlannerSupplement, setStepwisePlannerSupplement] = useState<string>(promptSettings.promptSettings.stepwisePlannerSupplement);
    //const [systemAudience, setSystemAudience] = useState<string>(promptSettings.promptSettings.systemAudience);
    //const [systemAudienceContinuation, setSystemAudienceContinuation] = useState<string>(promptSettings.promptSettings.systemAudienceContinuation);
    //const [systemCognitive, setSystemCognitive] = useState<string>(promptSettings.promptSettings.systemCognitive);
    const [systemDescription, setSystemDescription] = useState<string>(promptSettings.promptSettings.systemDescription);
    //const [systemIntent, setSystemIntent] = useState<string>(promptSettings.promptSettings.systemIntent);
    //const [systemIntentContinuation, setSystemIntentContinuation] = useState<string>(promptSettings.promptSettings.systemIntentContinuation);
    //const [systemResponse, setSystemResponse] = useState<string>(promptSettings.promptSettings.systemResponse);
    //const [workingMemoryExtraction, setWorkingMemoryExtraction] = useState<boolean>(promptSettings.promptSettings.workingMemoryExtraction);
    //const [workingMemoryName, setWorkingMemoryName] = useState<string>(promptSettings.promptSettings.workingMemoryName);
    const defaultModel = useState<string>(promptSettings.promptSettings.defaultModel);
    const [selectedModel, setSelectedModel] = defaultModel;
    const modelDeployments = useAppSelector((state) => state.modelDeployments.modelSettings);
    const [options] = React.useState<ModelDeployment[]>(modelDeployments);
    const comboId = useId('combo-openai-model-selector');

    const handleSave = () => {
        const newPromptSettings = {
            ...promptSettings.promptSettings,
            completionTokenLimit: completionTokenLimit,
            documentMemoryMinRelevance: documentMemoryMinRelevance,
            initialBotMessage:
                initialBotMessage.length > 5 ? initialBotMessage : promptSettings.promptSettings.initialBotMessage,
            responseTemperature: responseTemperature,
            responseTokenLimit: responseTokenLimit,
            responseTopP: responseTopP,
            systemDescription: systemDescription,
            defaultModel: selectedModel,
        };
        console.log(newPromptSettings);
        void prompt.setSettings(newPromptSettings).then(() => {
            dispatch({
                type: 'promptSettings/setPromptSettings',
                payload: newPromptSettings,
            });
        });
        setInitialBotMessage(newPromptSettings.initialBotMessage);
    };

    return (
        <div className={styles.base}>
            <div className={styles.button}>
                <h4>
                    <u>Prompt Settings</u>
                    <InfoLabel style={{ zIndex: 9998 }} size="small" info="Global Prompt Settings"></InfoLabel>
                </h4>
                <Button onClick={handleSave} icon={<Save16Regular />} />
            </div>
            <ParameterField
                required={false}
                info="The default chat completion model is used when model selection is disabled for the users. The default model is also used in the model selector until the user actively changes it."
                bannerTitle="Default Model"
            >
                <Dropdown
                    style={{ zIndex: 9990 }}
                    aria-labelledby={comboId}
                    placeholder={options[0].deployment}
                    appearance="underline"
                    inlinePopup={true}
                    defaultValue={selectedModel}
                    onOptionSelect={(_: SelectionEvents, data: OptionOnSelectData) => {
                        const value = data.optionValue ?? '';
                        setSelectedModel(value);
                    }}
                >
                    {options.map((option) => (
                        <Option key={option.deployment}>{option.deployment}</Option>
                    ))}
                </Dropdown>
            </ParameterField>
            <ParameterField
                required={true}
                info="System instructions passed to the LLM about how it should behave and any context it should reference when generating a response. This is used describe the chatbot personality, tell it what it should and shouldn't answer, and tell it how to format responses. There’s no token limit for this description, but it will be included with every API call, and will counts against the overall completion token limit."
                bannerTitle="System Description"
            >
                <Textarea
                    className={styles.textArea}
                    value={systemDescription.toString()}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSystemDescription(value);
                    }}
                />
            </ParameterField>
            <ParameterField
                info="This setting defines the initial message displayed when the chatbot initially engages with a new user prompt and helps set the language for the prompt session (the default is How can I help you today?)"
                bannerTitle="Initial Chatbot Message"
                required={true}
            >
                <Input
                    className={styles.Input}
                    value={initialBotMessage}
                    onChange={(e) => {
                        const value = e.target.value;
                        setInitialBotMessage(value);
                    }}
                />
            </ParameterField>
            <ParameterField
                required={true}
                info="This setting controls the maximum input tokens passed to the LLM as context for a user questions per user prompt."
                bannerTitle="Completion Token Limit"
            >
                <Input
                    className={styles.Input}
                    value={completionTokenLimit.toString()}
                    onChange={(e) => {
                        setCompletionTokenLimit(Number(e.target.value));
                    }}
                />
            </ParameterField>
            <ParameterField
                required={true}
                info="This setting controls the maximum output tokens to be utilized by the LLM for a model response per prompt."
                bannerTitle="Response Token Limit"
            >
                <Input
                    className={styles.Input}
                    value={responseTokenLimit.toString()}
                    onChange={(e) => {
                        setResponseTokenLimit(Number(e.target.value));
                    }}
                />
            </ParameterField>
            <ParameterField
                info="Similar to probability, this setting controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or probability, but not both"
                bannerTitle="Response Temperature"
            >
                <div className={styles.slider}>
                    <Slider
                        className={styles.sliderComponent}
                        defaultValue={responseTemperature}
                        min={0.0}
                        max={1.0}
                        step={0.05}
                        value={responseTemperature}
                        onChange={(e) => {
                            setResponseTemperature(Number(e.target.value));
                        }}
                        size="small"
                    />
                    <Label className={styles.sliderLabel} size="small">
                        {responseTemperature}
                    </Label>
                </div>
            </ParameterField>
            <ParameterField
                info="Similar to temperature, this setting controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or probability, but not both."
                bannerTitle="Response Probability"
            >
                <div className={styles.slider}>
                    <Slider
                        className={styles.sliderComponent}
                        defaultValue={responseTopP}
                        min={0.0}
                        max={1.0}
                        step={0.05}
                        value={responseTopP}
                        onChange={(e) => {
                            setResponseTopP(Number(e.target.value));
                        }}
                        size="small"
                    />
                    <Label className={styles.sliderLabel} size="small">
                        {responseTopP}
                    </Label>
                </div>
            </ParameterField>
            <ParameterField
                info="This setting controls the minimum cosine vector relevance score of context passed to the LLM from the knowledge base index service"
                bannerTitle="Relevance Score"
            >
                <div className={styles.slider}>
                    <Slider
                        className={styles.sliderComponent}
                        defaultValue={documentMemoryMinRelevance}
                        min={0.0}
                        max={1.0}
                        step={0.05}
                        value={documentMemoryMinRelevance}
                        onChange={(e) => {
                            setDocumentMemoryMinRelevance(Number(e.target.value));
                        }}
                        size="small"
                    />
                    <Label className={styles.sliderLabel} size="small">
                        {documentMemoryMinRelevance}
                    </Label>
                </div>
            </ParameterField>
        </div>
    );
};

export default ConfigurationDialog;

import { Field, FieldProps, InfoLabel, LabelProps } from '@fluentui/react-components';
import React from 'react';

export interface ParameterFieldProps extends FieldProps {
    info: string;
    bannerTitle: string;
    children: React.ReactNode;
    noInfo?: boolean;
}

export const ParameterField: React.FC<ParameterFieldProps> = ({ info, bannerTitle, children, noInfo, ...props }) => (
    <Field
        {...props}
        label={
            noInfo
                ? info
                : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  ({
                      children: (_: unknown, slotProps: LabelProps) => (
                          <InfoLabel style={{ zIndex: 9999 }} {...slotProps} info={info}>
                              {bannerTitle}
                          </InfoLabel>
                      ),
                  } as LabelProps)
        }
        size="small"
        orientation="horizontal"
    >
        {children}
    </Field>
);

import { ToggleButton, Tooltip, tokens } from '@fluentui/react-components';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store'; // Import the type of your root state
import { FeatureKeys } from '../../redux/features/app/AppState';
import { toggleFeatureFlag } from '../../redux/features/app/appSlice';
import { Navigation24 } from '../shared/BundledIcons';

export const ToggleChatListButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const { features } = useAppSelector((state: RootState) => state.app);
    const isChatListEnabled = features[FeatureKeys.ChatList].enabled;

    const onToggleClicked = useCallback(
        (featureKey: FeatureKeys) => {            
            dispatch(toggleFeatureFlag(featureKey));
        },
        [dispatch],
    );

    React.useEffect(() => {
        localStorage.setItem('chatHistoryActive', isChatListEnabled.toString().toLowerCase());
    }, [isChatListEnabled]);    

    return (
        <Tooltip showDelay={2000} content="View Chat History" relationship="label">
            <ToggleButton
                data-testid="toggleChatList"
                icon={
                    isChatListEnabled ? (
                        <Navigation24 color={tokens.colorBrandForeground1} />
                    ) : (
                        <Navigation24 color={tokens.colorNeutralForegroundOnBrand} />
                    )
                }
                appearance={isChatListEnabled ? 'secondary' : 'transparent'}
                onClick={() => {
                    onToggleClicked(FeatureKeys.ChatList);
                }}
                aria-label="Toggle chat list"
                size="medium"
            />
        </Tooltip>
    );
};

export interface FeaturesSetting {
    rlhf: boolean;
    modelSelection: boolean;
    webSearch: boolean;
    webScraping: boolean;
    textToImage: boolean;
}

export function createFeaturesSetting(): FeaturesSetting {
    return {
        modelSelection: false,
        rlhf: false,
        textToImage: false,
        webScraping: false,
        webSearch: false,
    };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeaturesSetting } from '../../../libs/models/FeaturesSetting';
import { FeaturesSettingState, initialState } from './FeaturesSettingState';

const featuresSettingSlice = createSlice({
    name: 'featuresSetting',
    initialState,
    reducers: {
        setFeaturesSetting: (state: FeaturesSettingState, action: PayloadAction<FeaturesSetting>) => {
            state.featuresSetting = action.payload;
        },
    },
});

export default featuresSettingSlice.reducer;

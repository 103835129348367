import { IAgentOptions } from '../models/Agent';
import { BaseService } from './BaseService';

export class AgentService extends BaseService {
    constructor(serviceUrl: string) {
        super(serviceUrl);
        this.serviceUrl = serviceUrl;
    }

    public getOptionsListAsync = async (accessToken: string): Promise<IAgentOptions[]> => {
        const agentOptionsResponse = await this.getResponseAsync<IAgentOptions[]>(
            {
                commandPath: `agents`,
                method: 'GET',
            },
            accessToken,
        );
        return agentOptionsResponse;
    };

    public getOptionsAsync = async (accessToken: string, hostName: string) => {
        const agentOptionsResponse = await this.getResponseAsync<IAgentOptions>(
            {
                commandPath: `agents/${hostName}`,
                method: 'GET',
            },
            accessToken,
        );
        return agentOptionsResponse;
    };
    public setOptionsAsync = async (accessToken: string, hostName: string, options: IAgentOptions) => {
        const agentOptionsResponse = await this.getResponseAsync(
            {
                commandPath: `agents/${hostName}`,
                method: 'POST',
                body: options,
            },
            accessToken,
        );
        return agentOptionsResponse;
    };
    public deleteOptionsAsync = async (accessToken: string, hostName: string) => {
        const agentOptionsResponse = await this.getResponseAsync(
            {
                commandPath: `agents/${hostName}`,
                method: 'DELETE',
            },
            accessToken,
        );
        return agentOptionsResponse;
    };
}

import { IMember } from '../models/Member';
import { IGetToken, IPath } from '../models/Path';
import { BaseService } from './BaseService';

export class RoleService extends BaseService {
    public getUserRolesAsync = async (accessToken: string) => {
        const userRoleResponse = await this.getResponseAsync<object>(
            {
                commandPath: 'roles',
                method: 'GET',
            },
            accessToken,
        );
        return userRoleResponse;
    };
    public getEntraIDRolesAsync = async (accessToken: string) => {
        const entraIdRoleResponse = await this.getResponseAsync<object>(
            {
                commandPath: 'rolesentraid',
                method: 'GET',
            },
            accessToken,
        );
        return entraIdRoleResponse;
    };
    public addUserRoleAsync = async (accessToken: string, userId: string, groupId: string) => {
        const member: IMember = { id: userId };

        return await this.getResponseAsync<object>(
            {
                commandPath: `groups/${groupId}/members`,
                method: 'POST',
                body: member,
            },
            accessToken,
        );
    };
    public deleteUserRoleAsync = async (accessToken: string, userId: string, groupId: string) => {
        return await this.getResponseAsync<object>(
            {
                commandPath: `groups/${groupId}/members/${userId}`,
                method: 'DELETE',
            },
            accessToken,
        );
    };

    public getBlobSASTokenAsync = async (accessToken: string, getToken: IGetToken): Promise<IPath> => {
        const sasTokenResponse = await this.getResponseAsync<IPath>(
            {
                commandPath: 'token/blob',
                method: 'POST',
                body: getToken,
            },
            accessToken,
        );
        return sasTokenResponse;
    };
}

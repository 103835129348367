import { CustomSettings } from '../models/CustomSettings';
import { BaseService } from './BaseService';
export class SettingsService extends BaseService {
    public getSettingsAsync = async (accessToken: string) => {
        const settingsResponse = await this.getResponseAsync<object>(
            {
                commandPath: 'settings',
                method: 'GET',
            },
            accessToken,
        );
        return settingsResponse;
    };

    public setSettingsAsync = async (accessToken: string, customSettings: CustomSettings) => {
        const settingsResponse = await this.getResponseAsync<boolean>(
            {
                commandPath: 'settings',
                method: 'POST',
                body: customSettings,
            },
            accessToken,
        );
        return settingsResponse;
    };
}

import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { AlertType } from '../../../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { addAlert } from '../../../../redux/features/app/appSlice';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

export const DeleteAllChatsDialog: React.FC = () => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const onDeleteChat = async () => {
        dispatch(addAlert({ message: 'Starting to delete all chat sessions. Please wait.', type: AlertType.Warning }));
        const chatIds = Object.keys(conversations);
        for (const [index, chatId] of chatIds.entries()) {
            const isLastChat = index === chatIds.length - 1;
            await chat.deleteChat(chatId, true, isLastChat);
            if (isLastChat) {
                dispatch(addAlert({ message: 'All chat sessions have been deleted.', type: AlertType.Success }));
            }
        }
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete all chat sessions'} relationship="label">
                    <a style={{ cursor: 'pointer', textDecoration: 'underline' }}>Delete all chat sessions</a>
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete all your chats?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete all your chat sessions, including any attached documents and
                        associated memories, for all participants.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    void (async () => {
                                        await onDeleteChat();
                                    })();
                                }}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export interface Variant {
    v10: string;
    v20: string;
    v30: string;
    v40: string;
    v50: string;
    v60: string;
    v70: string;
    v80: string;
    v90: string;
    v100: string;
    v110: string;
    v120: string;
    v130: string;
    v140: string;
    v150: string;
    v160: string;
}

export interface CustomSettings {
    welcomeTitle: string;
    welcomeSubtitle: string;
    welcomeText: string;
    welcomeSubtitle2: string;
    welcomeImageUrl: string;
    welcomeQButton1: string;
    welcomeQButton2: string;
    welcomeQButton3: string;
    welcomeQButton4: string;
    welcomeQButton5: string;
    welcomeQButton6: string;
    appTitle: string;
    brandVariants: Variant;
    mainAgent: string;
    allowPortal: boolean;
    portalHostName: string;
    portalName: string;
    portalBannerColor: string;
    portalBannerLink?: string;
}

export function createCustomSettings(): CustomSettings {
    return {
        welcomeTitle: '',
        welcomeSubtitle: '',
        welcomeText: '',
        welcomeSubtitle2: '',
        welcomeImageUrl: '',
        welcomeQButton1: '',
        welcomeQButton2: '',
        welcomeQButton3: '',
        welcomeQButton4: '',
        welcomeQButton5: '',
        welcomeQButton6: '',
        appTitle: '',
        brandVariants: {
            v10: '',
            v20: '',
            v30: '',
            v40: '',
            v50: '',
            v60: '',
            v70: '',
            v80: '',
            v90: '',
            v100: '',
            v110: '',
            v120: '',
            v130: '',
            v140: '',
            v150: '',
            v160: '',
        },
        mainAgent: '',
        allowPortal: false,
        portalHostName: '',
        portalName: '',
        portalBannerColor: '',
        portalBannerLink: '',
    };
}

//const defaultSettings = createDefaultSettings();
